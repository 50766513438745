import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import JSZip from "jszip";
import { saveAs } from "file-saver";

interface Font {
  id: string;
  name: string;
  weight: string;
}

const VARIABLE_FONT_ID = "prod_R6HukUqmlKSY4s";

const fonts: Font[] = [
  { id: "R6HuMRjDuFgOuD", name: "JL Uxum Ultra Light", weight: "Ultra Light" },
  { id: "R6HudmqwMJpe1W", name: "JL Uxum Light", weight: "Light" },
  { id: "R6HuXZxXViKF4Y", name: "JL Uxum Regular", weight: "Regular" },
  { id: "R6HuTcY9hf3ZZa", name: "JL Uxum Medium", weight: "Medium" },
  { id: "R6Hug93thJR5CJ", name: "JL Uxum Bold", weight: "Bold" },
  { id: "R6Hu1HjQyxFcqT", name: "JL Uxum Ultra", weight: "Ultra" }, 
  { id: "R6HukUqmlKSY4s", name: "Uxum Family + Variable", weight: "variable" },
];

const SuccessPage: React.FC = () => {
  const [purchasedFonts, setPurchasedFonts] = useState<Font[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const fetchPurchasedFonts = async () => {
      const searchParams = new URLSearchParams(location.search);
      const sessionId = searchParams.get("session_id");

      console.log("Session ID from URL:", sessionId); // Debugging

      if (!sessionId) {
        setError("Session ID not found");
        setLoading(false);
        return;
      }

      try {
        const response = await fetch(
          `/api/check-session?sessionId=${sessionId}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch session details");
        }
        const data = await response.json();
        if (!data.success) {
          throw new Error(data.error || "Failed to retrieve session data");
        }
        console.log("Product IDs from API:", data.productIds); // Pour le débogage
        const fontsPurchased = fonts.filter((font) =>
          data.productIds.includes(`prod_${font.id}`)
        );
        console.log("Filtered purchased fonts:", fontsPurchased);
        setPurchasedFonts(fontsPurchased);
      } catch (err) {
        console.error("Error fetching session:", err); // Debugging
        setError(err instanceof Error ? err.message : "An error occurred");
      } finally {
        setLoading(false);
      }
    };

    fetchPurchasedFonts();
  }, [location.search]);

  const handleDownload = async () => {
    try {
      const zip = new JSZip();

      // Create folders for all purchases
      const staticFolder = zip.folder("01_Static");
      const docsFolder = zip.folder("02_Documentation");

      console.log("Checking purchased fonts:", purchasedFonts);
      const hasVariableFont = purchasedFonts.some(
        (font) => `prod_${font.id}` === VARIABLE_FONT_ID
      );
      console.log("Has variable font:", hasVariableFont);

      if (hasVariableFont) {
        const variableFolder = zip.folder("00_Variable");
        const staticFolder = zip.folder("01_Static");
        
        // Polices statiques et variable avec les bons IDs
        const individualFonts = [
          { id: "R6HuMRjDuFgOuD", name: "JL Uxum Ultra Light", fileName: "UxumUltraLight" },
          { id: "R6HudmqwMJpe1W", name: "JL Uxum Light", fileName: "UxumLight" },
          { id: "R6HuXZxXViKF4Y", name: "JL Uxum Regular", fileName: "UxumRegular" },
          { id: "R6HuTcY9hf3ZZa", name: "JL Uxum Medium", fileName: "UxumMedium" },
          { id: "R6Hug93thJR5CJ", name: "JL Uxum Bold", fileName: "UxumBold" },
          { id: "R6Hu1HjQyxFcqT", name: "JL Uxum Ultra", fileName: "UxumUltra" },
          { id: "R6HukUqmlKSY4s", name: "Uxum Family + Variable", fileName: "UxumVF" }
        ];

        // Ajouter les polices statiques et variable
        for (const font of individualFonts) {
          const formats = font.id === "R6HukUqmlKSY4s" 
            ? ["ttf", "woff", "woff2"]  // Formats pour la police variable
            : ["otf", "woff", "woff2"]; // Formats pour les polices statiques
          
          for (const format of formats) {
            try {
              const response = await fetch(`/fonts/prod_${font.id}.${format}`);
              if (!response.ok) continue;
              const blob = await response.blob();
              
              // Choisir le dossier approprié selon le type de police
              const targetFolder = font.id === "R6HukUqmlKSY4s" ? variableFolder : staticFolder;
              targetFolder?.file(`${font.fileName}.${format}`, blob);
            } catch (error) {
              console.error(`Error adding ${font.fileName}.${format}:`, error);
            }
          }
        }

        // Enfin les documents PDF
        const additionalFiles = [
          "Uxum_Specimen24.pdf",
          "Uxum_LicencesTerms.pdf",
          "Proofing_Aperçu_UXUMGrot.pdf",
        ];

        for (const fileName of additionalFiles) {
          const response = await fetch(`/fonts/${fileName}`);
          if (response.ok) {
            const blob = await response.blob();
            docsFolder?.file(fileName, blob);
          }
        }
      } else {
        // Logic for individual purchases (without variable folder)
        for (const font of purchasedFonts) {
          const formats = ["otf", "woff", "woff2"];
          for (const format of formats) {
            try {
              const response = await fetch(`/fonts/prod_${font.id}.${format}`);
              if (!response.ok) continue;
              const blob = await response.blob();
              staticFolder?.file(`${font.name}.${format}`, blob);
            } catch (error) {
              console.error(`Error adding ${font.name}.${format}:`, error);
            }
          }
        }
      }

      // Add documentation PDFs for all purchases
      const additionalFiles = [
        "Uxum_Specimen24.pdf",
        "Uxum_LicencesTerms.pdf",
        "Proofing_Aperçu_UXUMGrot.pdf",
      ];

      for (const fileName of additionalFiles) {
        try {
          const response = await fetch(`/fonts/${fileName}`);
          if (response.ok) {
            const blob = await response.blob();
            docsFolder?.file(fileName, blob);
          }
        } catch (error) {
          console.error(`Error adding ${fileName}:`, error);
        }
      }

      console.log("Generating final zip...");
      const content = await zip.generateAsync({ type: "blob" });
      saveAs(content, "uxum_fonts_package.zip");
    } catch (err) {
      console.error("Download error:", err);
      setError(err instanceof Error ? err.message : "Failed to download files");
    }
  };

  if (loading) {
    return (
      <div className="loading-container">
        <div className="loading-spinner">Loading...</div>
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (purchasedFonts.length === 0) {
    return <div>No fonts were purchased.</div>;
  }

  return (
    <div id="purchase" className="thankyou">
      <h1>Thank you for your purchase!</h1>
      <button className="cta primary big" onClick={handleDownload}>
        Download
      </button>
      {error && <div>Error: {error}</div>}
    </div>
  );
};

export default SuccessPage;
